@import './assets/styles/colors';

@import "../node_modules/primeng/resources/primeng.min.css";
//@import "../node_modules/primeng/resources/themes/mdc-light-indigo/theme.css";
@import "../src/assets/styles/_mdc-light-blue.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

:root {
    --header-height: 50px;
    --sidebar-width: 250px;
}

body, html {
    font-family: 'Roboto', sans-serif;
    // PrimeNG utilize rem units so components are scaled relative to font size.
    font-size: 14px;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

h1 {
    font-size: 26px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $blue;
    margin: 0 0 1rem 0;
}

h2 {
    font-size: 22px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $blue;
    margin: 0;
    margin: 0 0 0.5rem 0;
}

h3 {
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $blue;
    margin: 0;
    margin: 0 0 0.25rem 0;
}

.content-page {
    max-width: 1200px;
    padding: 2rem 3rem;
}

.panel-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    color: $white;
    padding: 0.5rem;
}

.panel-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1.5rem;
    background-color: $white;
}

.scroll-area {
    background-color: $white;
}

.hidden {
    display: none;
}

.button-fill {
    width: 100%;
    height: 100%;
}

.button-confirm {
    background-color: $green;
}

.button-cancel {
    background-color: $red;
}

//PrimeFlex & PrimeNG overrides
.field > label {
    margin-bottom: unset !important;
}

.p-card {
    box-shadow: 0 2px 1px -1px $lightest-gray !important;
    border: 1px solid $lightest-gray;
}

.form-dialog .p-dialog-content {
    overflow-y: unset !important;
}

.p-panel {
    border: 1px $lightest-gray solid !important;
    box-shadow: none !important;
}

.p-panel.p-panel-toggleable .p-panel-header {
    padding: 0.5rem !important;
    background: none !important;
}

.p-panel-title {
    margin-left: 0.5rem !important;
}

.p-panel .p-panel-content {
    padding: 0.25rem;
    margin-top: -0.5rem;
}

.p-panel-header-icon {
    border: 1px $light-gray solid !important;
}

.p-card .p-card-content {
    padding: 0.5rem 0;
}

.p-card .p-card-body {
    padding: 0.5rem 0.5rem 0 0.5rem;
}

.p-dropdown-panel.p-component {
    transform-origin: top right !important;
    left: unset !important;
    right: 0px !important;
}

.filter-content > .col-12, .filter-content > .col-11, .filter-content > .col-10,
.filter-content > .col-9, .filter-content > .col-8, .filter-content > .col-7,
.filter-content > .col-6, .filter-content > .col-5, .filter-content > .col-4,
.filter-content > .col-3, .filter-content > .col-2, .filter-content > .col-1 {
    padding: 0.25rem !important;
}

.account-overview .p-card .p-card-body {
    padding: 0px !important;
}

.account-overview .p-card-content {
    background-color: $blue;
    color: $white;
    border-radius: 3px;
}

.account-overview .gray-card .p-card-content {
    background-color: $lightest-gray;
    color: $dark-red;
}

.account-overview .table-header {
    background-color: $blue;
}

.account-overview .table-header-text {
    color: $white;
}