$dark-red:          #6b2721;
$red:               #a3342a;
$blue:              #092437;
$light-blue:        #263f52;
$dark-green:        #30572a;
$green:             #89a112;
$black:             #0f110f;
$darkest-gray:      #363b39;
$dark-gray:         #4b4e52;
$dark-alt-gray:     #56595e;
$gray:              #919395;
$light-gray:        #ccc;
$lightest-gray:     #ddd;
$white:             #fafafa;
$offwhite:          #ebebeb;